// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions"; // Importar funciones

const firebaseConfig = {
  apiKey: "AIzaSyA_l2axhckbIeKxwbavo65x-dQdP1axExU",
  authDomain: "azurcr-co.firebaseapp.com",
  databaseURL: "https://azurcr-co-default-rtdb.firebaseio.com",
  projectId: "azurcr-co",
  storageBucket: "azurcr-co.appspot.com",
  messagingSenderId: "292006675612",
  appId: "1:292006675612:web:582395916115affeb85959",
  measurementId: "G-72FD4WFV1X",
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp); // Aquí inicializas la autenticación
export const storage = getStorage(firebaseApp); // Exportar storage
export const functions = getFunctions(firebaseApp); // Inicializas las funciones

export { firebaseApp }; // Exportar app
