import React, { useState, useEffect, useContext } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ComercioContext from "../ComercioContext";
import { styled } from "@mui/system";

const ProductsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Products = () => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [products, setProducts] = useState([]);
  const { comercioId } = useContext(ComercioContext);

  useEffect(() => {
    const productsRef = collection(db, "Productos");
    const unsubscribe = onSnapshot(productsRef, (snapshot) => {
      const productsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    });

    return () => unsubscribe();
  }, []);

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (!productName || !productDescription || !productPrice) {
      alert("Todos los campos son obligatorios.");
      return;
    }

    try {
      await addDoc(collection(db, "Productos"), {
        nombre: productName,
        descripcion: productDescription,
        precio: parseFloat(productPrice),
        comerciouid: comercioId,
      });
      setProductName("");
      setProductDescription("");
      setProductPrice("");
      alert("Producto añadido con éxito.");
    } catch (error) {
      console.error("Error añadiendo el producto:", error);
      alert(
        "Hubo un error al añadir el producto. Por favor, intenta de nuevo."
      );
    }
  };

  return (
    <ProductsContainer>
      <Typography variant="h4" gutterBottom>
        Agregar Producto
      </Typography>
      <form onSubmit={handleAddProduct}>
        <TextField
          label="Nombre del Producto"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Descripción del Producto"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Precio del Producto"
          type="number"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Agregar Producto
        </Button>
      </form>
      <Typography variant="h5" gutterBottom style={{ marginTop: "2rem" }}>
        Productos Existentes
      </Typography>
      <List>
        {products.map((product) => (
          <ListItem key={product.id}>
            <ListItemText
              primary={product.nombre}
              secondary={`${product.descripcion} - $${product.precio}`}
            />
          </ListItem>
        ))}
      </List>
    </ProductsContainer>
  );
};

export default Products;
