import React, { useEffect, useState, useContext, useRef } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  updateDoc,
} from "firebase/firestore";
import { format } from "date-fns";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Collapse,
  ListItemAvatar,
  Drawer,
  ListSubheader,
  Divider,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ListItemIcon,
  Toolbar,
  AppBar,
  CssBaseline,
  Switch,
} from "@mui/material";
import { styled } from "@mui/system";
import { convertStatusToText } from "../utils/statusConverter";
import {
  AssignmentTurnedIn,
  LocalShipping,
  Done,
  Cancel,
  Refresh,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import ComercioContext from "../ComercioContext";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { Link, useLocation } from "react-router-dom";
import {
  Add as AddIcon,
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category"; // Importa el icono aquí

const drawerWidth = 240;

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
}));

const OrdersContent = styled(Box)(({ theme }) => ({
  marginTop: 15,
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: "#eceff1",
  minHeight: "100vh",
  marginLeft: drawerWidth,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const OrdersCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  transition: "0.3s",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const OrderListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  margin: theme.spacing(1, 0),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  transition: "0.3s",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const DashboardHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "0.1em",
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FilterGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const MapContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "400px",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const { comercioId } = useContext(ComercioContext);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [riders, setRiders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRider, setSelectedRider] = useState("");
  const location = useLocation();
  const [statusZeroCount, setStatusZeroCount] = useState(0);
  const prevStatusZeroCountRef = useRef(statusZeroCount);
  const filterRef = useRef("all"); // Usamos useRef para persistir el valor del filtro
  const [darkMode, setDarkMode] = useState(false);

  const audioRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDjeDqnDxgQDIT9X7HDURHaAnidke-XkzQ",
  });

  const fetchOrders = async () => {
    let ordersQuery = collection(db, "Ordenes");

    if (filterStatus !== "all") {
      ordersQuery = query(
        ordersQuery,
        where("comerciouid", "==", comercioId),
        where("orderStatus", "==", parseInt(filterStatus)),
        orderBy("createData", "desc")
      );
    } else {
      ordersQuery = query(
        ordersQuery,
        where("comerciouid", "==", comercioId),
        orderBy("createData", "desc")
      );
    }

    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
      const updatedOrders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Contar el número de pedidos con status igual a 0
      const currentStatusZeroCount = updatedOrders.filter(
        (order) => order.orderStatus === 0
      ).length;

      // Log para depuración
      console.log("Previous StatusZeroCount:", prevStatusZeroCountRef.current);
      console.log("Current StatusZeroCount:", currentStatusZeroCount);
      console.log("filter", filterStatus);

      // Si la cantidad de pedidos con status 0 ha aumentado, reproducir el sonido
      if (filterRef.current === "0") {
        if (
          currentStatusZeroCount > prevStatusZeroCountRef.current &&
          audioRef.current
        ) {
          console.log("Sound triggered: StatusZeroCount increased");
          audioRef.current.play();
        }
      }

      setOrders(updatedOrders);
      setStatusZeroCount(currentStatusZeroCount);

      prevStatusZeroCountRef.current = currentStatusZeroCount;
    });

    return unsubscribe;
  };

  const fetchRiders = async () => {
    const ridersQuery = collection(db, "motorizados");
    const queryrider = query(
      ridersQuery,
      where("uidcomercio", "==", comercioId),
      where("active", "==", true)
    );
    const unsubscribe = onSnapshot(queryrider, (snapshot) => {
      const updatedRiders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRiders(updatedRiders);
    });

    return unsubscribe;
  };

  useEffect(() => {
    filterRef.current = filterStatus;
    fetchOrders();
    fetchRiders();
  }, [filterStatus, comercioId]);

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 1:
        return <AssignmentTurnedIn />;
      case 3:
        return <LocalShipping />;
      case 4:
        return <Done />;
      case -1:
        return <Cancel />;
      default:
        return <AssignmentTurnedIn />;
    }
  };

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleRefresh = () => {
    fetchOrders();
  };

  const handleExpandClick = (orderId) => {
    setExpandedOrderId((prevExpandedOrderId) =>
      prevExpandedOrderId === orderId ? null : orderId
    );
    setSelectedOrder(orders.find((order) => order.id === orderId));
  };

  const handleChangeStatus = async (orderId, newStatus) => {
    const orderDoc = doc(db, "Ordenes", orderId);
    await updateDoc(orderDoc, {
      orderStatus: newStatus,
    });
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setOpenDialog(true);
  };

  const handleRiderChange = (event) => {
    setSelectedRider(event.target.value);
  };

  const handleAssignRider = async () => {
    if (selectedOrder && selectedRider) {
      try {
        // Obtener una referencia al documento del pedido en Firestore
        //   const orderRef = db.collection("Ordenes").doc(selectedOrder.id);
        const orderRef = doc(db, "Ordenes", selectedOrder.id);

        // Actualizar el estado del pedido y asignar el motorizado seleccionado
        await updateDoc(orderRef, {
          orderStatus: 3, // Cambiar el estado a "Enviado"
          riderId: selectedRider, // Asignar el ID del motorizado
        });

        // Cerrar el cuadro de diálogo y resetear el estado de los motorizados seleccionados
        setOpenDialog(false);
        setSelectedOrder(null);
        setSelectedRider("");

        // Mostrar un mensaje de éxito (opcional)
        alert(
          "El pedido ha sido marcado como Enviado y se ha asignado el motorizado."
        );
      } catch (error) {
        console.error("Error al actualizar el pedido:", error);
        // Mostrar un mensaje de error (opcional)
        alert(
          "Hubo un error al actualizar el pedido. Por favor, intenta de nuevo."
        );
      }
    } else {
      // Mostrar un mensaje de error si no se ha seleccionado un motorizado (opcional)
      alert("Por favor, selecciona un motorizado.");
    }
  };

  const handleChangeMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Panel de Ordenes
          </Typography>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        variant="permanent"
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/POS/${comercioId}`}
            isActive={isActiveRoute(`/POS/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="POS" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/products/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippers/${comercioId}`}
            isActive={isActiveRoute(`/shippers/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Motorizados" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>
      <OrdersContent>
        <audio ref={audioRef}>
          <source src="/sounds/neworder.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DashboardHeader variant="h4">Ordenes Recientes</DashboardHeader>
          <FilterContainer>
            <FilterGroup>
              <FormControl variant="outlined">
                <InputLabel id="status-filter-label">
                  Filtrar por Estado
                </InputLabel>
                <Select
                  labelId="status-filter-label"
                  value={filterStatus}
                  onChange={handleFilterChange}
                  label="Filtrar por Estado"
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="0">PreOrden</MenuItem>
                  <MenuItem value="1">Orden Aceptada</MenuItem>
                  <MenuItem value="2">Central Tico Compramos</MenuItem>
                  <MenuItem value="3">Enviado</MenuItem>
                  <MenuItem value="4">Entregado</MenuItem>
                  <MenuItem value="-1">Cancelado</MenuItem>
                </Select>
              </FormControl>
              <IconButton color="primary" onClick={handleRefresh}>
                <Refresh />
              </IconButton>
            </FilterGroup>
          </FilterContainer>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrdersCard>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Pedidos Recientes
                  </Typography>
                  <List>
                    {orders.map((order) => (
                      <React.Fragment key={order.id}>
                        <OrderListItem>
                          <Badge
                            badgeContent={order.cartItemList.length}
                            color="secondary"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Avatar
                              sx={{ bgcolor: "primary.main", marginRight: 2 }}
                            >
                              {getStatusIcon(order.orderStatus)}
                            </Avatar>
                          </Badge>
                          <ListItemText
                            primary={`Pedido ID: ${order.id}`}
                            secondary={`Cliente: ${order.userName}, Total: ¢${
                              order.totalPayment
                            }, Estado: ${convertStatusToText(
                              order.orderStatus
                            )}, Fecha: ${format(
                              new Date(order.createData),
                              "dd/MM/yyyy HH:mm"
                            )}`}
                          />
                          <IconButton
                            onClick={() => handleExpandClick(order.id)}
                            aria-expanded={expandedOrderId === order.id}
                            aria-label="show more"
                          >
                            {expandedOrderId === order.id ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </OrderListItem>
                        <Collapse
                          in={expandedOrderId === order.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <CardContent>
                            <Typography variant="h6">
                              Productos del Pedido:
                            </Typography>
                            <List>
                              {order.cartItemList.map((item) => (
                                <ListItem key={item.foodId}>
                                  <ListItemAvatar>
                                    <Avatar
                                      src={item.foodImage}
                                      alt={item.foodName}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.foodName}
                                    secondary={`Cantidad: ${item.foodQuantity}, Precio: ¢${item.foodPrice}`}
                                  />
                                  <Divider variant="inset" component="li" />
                                </ListItem>
                              ))}
                            </List>
                            <Box mt={2}>
                              <Typography variant="h6">
                                Dirección de Envío:
                              </Typography>
                              <Typography>{`${order.shippingAddress}`}</Typography>
                              <Box mt={2}>
                                <Typography variant="h6">
                                  Ubicación en Mapa:
                                </Typography>
                                {/* Mostrar el mapa */}
                                <MapContainer>
                                  {isLoaded ? (
                                    <GoogleMap
                                      mapContainerStyle={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      center={{
                                        lat: parseFloat(order.lat),
                                        lng: parseFloat(order.lng),
                                      }}
                                      zoom={15}
                                    >
                                      <MarkerF
                                        position={{
                                          lat: parseFloat(order.lat),
                                          lng: parseFloat(order.lng),
                                        }}
                                      />
                                    </GoogleMap>
                                  ) : (
                                    <div>Cargando mapa...</div>
                                  )}
                                </MapContainer>
                              </Box>
                              <Box mt={2}>
                                <Typography variant="h6">
                                  Cambiar Estado del Pedido:
                                </Typography>
                                {order.orderStatus < 1 && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleChangeStatus(order.id, 1)
                                    }
                                  >
                                    Aceptar Pedido
                                  </Button>
                                )}
                                {order.orderStatus === 1 && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleOrderSelect(order)}
                                    disabled={order.orderStatus !== 1} // Habilitar solo si el estado es "Orden Aceptada"
                                  >
                                    Enviar
                                  </Button>
                                )}
                                {order.orderStatus === 3 && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleChangeStatus(order.id, 4)
                                    }
                                  >
                                    Marcar como Entregado
                                  </Button>
                                )}
                                {order.orderStatus !== -1 && (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleChangeStatus(order.id, -1)
                                    }
                                  >
                                    Cancelar Pedido
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </CardContent>
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </List>
                </CardContent>
              </OrdersCard>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Asignar Motorizado</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Selecciona un motorizado para el pedido{" "}
              {selectedOrder && selectedOrder.id}.
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel id="rider-select-label">Motorizado</InputLabel>
              <Select
                labelId="rider-select-label"
                value={selectedRider}
                onChange={handleRiderChange}
                label="Motorizado"
              >
                {riders.map((rider) => (
                  <MenuItem key={rider.id} value={rider.id}>
                    {rider.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleAssignRider} color="primary">
              Asignar y Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </OrdersContent>
    </Box>
  );
}

export default Dashboard;
