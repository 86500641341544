import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";

import { db, storage } from "../firebaseConfig";
import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  Fab,
  Drawer,
  List,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  IconButton,
  Menu,
  Toolbar,
  AppBar,
  CssBaseline,
} from "@mui/material";
import {
  Add as AddIcon,
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
  Add,
} from "@mui/icons-material";

import { Edit, Star, StarBorder, Delete, Favorite } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category"; // Importa el icono aquí
import MenuIcon from "@mui/icons-material/Menu";

import ComercioContext from "../ComercioContext";
import { styled } from "@mui/system";

const ProductsContainer = styled(Grid)(({ theme }) => ({
  marginTop: 10,
  backgroundColor: "#f5f5f5",
  minHeight: "100vh",
}));
const drawerWidth = 240;
const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));
const ProductsContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#f5f5f5",
}));

const ProductImage = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: "56.25%", // 16:9
}));

const ProductCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: "auto",
  transition: "0.3s",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[6],
  backgroundColor: theme.palette.background.paper,
  position: "relative",
  overflow: "visible",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[8],
  },
}));

const ProductContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const ProductName = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  lineClamp: 2,
  textOverflow: "ellipsis",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const ProductDescription = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  lineClamp: 3,
  textOverflow: "ellipsis",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({ open }) => ({
  flexGrow: 1,
  padding: 24,
  backgroundColor: "#fafafa",
  minHeight: "100vh",
  marginLeft: open ? drawerWidth : 0,
  transition: "margin-left 0.3s",
}));

const FabButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const CardActionsStyled = styled(CardActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(1),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Products = () => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productImage, setProductImage] = useState(null); // Estado para la imagen seleccionada
  const [productImageUrl, setProductImageUrl] = useState(""); // Estado para la URL de la imagen actual
  const [productCategory, setProductCategory] = useState("");

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [lastProduct, setLastProduct] = useState(null); // Guarda el último producto cargado para la paginación
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false); // Nuevo estado para el diálogo de creación

  const { id: comercioId } = useParams(); // Obtener comercioId desde los parámetros de la ruta
  const { tipoComercio } = useContext(ComercioContext); // Obtener tipoComercio del contexto

  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openmenu, setOpenmenu] = useState(true);

  const handleClick = (event, product) => {
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedProduct(null);
  };
  useEffect(() => {
    loadInitialProducts();
    loadCategories();
  }, []); // Cargar productos al montar el componente

  const loadInitialProducts = async () => {
    setLoading(true);
    try {
      const productsRef = collection(db, "Productos");
      const q = query(
        productsRef,
        where("idcomercio", "==", comercioId),
        orderBy("name"),
        limit(12)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const newProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          key: doc.id,
          ...doc.data(),
        }));
        setProducts(newProducts);
        setLastProduct(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        console.log("No products found");
      }
    } catch (error) {
      console.error("Error loading initial products: ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadCategories = async () => {
    try {
      const categoriesRef = collection(
        db,
        `Ciudades/Ciudad Quesada/${tipoComercio}/${comercioId}/Category`
      );
      const querySnapshot = await getDocs(categoriesRef);
      const categoriesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,

        ...doc.data(),
      }));
      setCategories(categoriesList);
    } catch (error) {
      console.error("Error loading categories:", error);
    }
  };

  const loadMoreProducts = async () => {
    setLoading(true);
    try {
      const productsRef = collection(db, "Productos");
      let q = query(
        productsRef,
        where("idcomercio", "==", comercioId),
        orderBy("name"),
        startAfter(lastProduct),
        limit(12)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const newProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          key: doc.id,

          ...doc.data(),
        }));
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setLastProduct(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        console.log("No more products to load");
      }
    } catch (error) {
      console.error("Error loading more products: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    loadMoreProducts();
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (
      !productName ||
      !productDescription ||
      !productPrice ||
      !productCategory
    ) {
      alert("Todos los campos son obligatorios.");
      return;
    }

    let imageUrl = "";
    if (productImage) {
      const imageRef = ref(storage, `productos/${selectedProduct.name}`);
      const snapshot = await uploadBytes(imageRef, productImage);
      const originalImageUrl = await getDownloadURL(snapshot.ref);

      // Agregar un retraso antes de obtener la URL de la imagen redimensionada
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 segundos

      imageUrl = await getResizedImageUrl(imageRef.name, 800, 800); // Aquí obtenemos la URL de la imagen redimensionada
    }

    try {
      await addDoc(collection(db, "Productos"), {
        name: productName,
        description: productDescription,
        price: productPrice,
        idcomercio: comercioId,
        image: imageUrl,

        productonuevo: true,
        productousado: false,
        tiempoentrega: "0",

        codigo: "",
        id: productName,
        idproductodestacados: "",
        idproductopopulares: "",
        addon: null,
        color: null,
        size: null,
        idcategory: productCategory,

        idcomercio: comercioId,
        porcetajedescuento: 0,
        descuento: false,
        untilDate: 0,
      });
      setProductName("");
      setProductDescription("");
      setProductPrice("");
      setProductImage(null);
      alert("Producto añadido con éxito.");
    } catch (error) {
      console.error("Error añadiendo el producto:", error);
      alert(
        "Hubo un error al añadir el producto. Por favor, intenta de nuevo."
      );
    }
  };

  const handleEditProduct = async () => {
    if (
      !selectedProduct ||
      !productName ||
      !productDescription ||
      !productPrice ||
      !productCategory
    ) {
      alert("Todos los campos son obligatorios.");
      return;
    }

    let imageUrl = productImageUrl;
    if (productImage) {
      const imageRef = ref(storage, `productos/${selectedProduct.key}`);
      const snapshot = await uploadBytes(imageRef, productImage);
      const originalImageUrl = await getDownloadURL(snapshot.ref);

      // Agregar un retraso antes de obtener la URL de la imagen redimensionada
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 segundos

      imageUrl = await getResizedImageUrl(imageRef.name, 800, 800); // Aquí obtenemos la URL de la imagen redimensionada
    }

    try {
      const productRef = doc(db, "Productos", selectedProduct.key);
      await updateDoc(productRef, {
        name: productName,
        description: productDescription,
        price: productPrice,
        image: imageUrl,
        idcategory: productCategory,
      });
      setDialogOpen(false);
      alert("Producto actualizado con éxito.");
    } catch (error) {
      console.error("Error actualizando el producto:", error);
      alert(
        "Hubo un error al actualizar el producto. Por favor, intenta de nuevo."
      );
    }
  };

  const getResizedImageUrl = async (fileName, width, height) => {
    const resizedFileName = `${fileName}_${width}x${height}`;
    const resizedFileRef = ref(storage, `productos/${resizedFileName}`);
    return await getDownloadURL(resizedFileRef);
  };

  const handleOpenEditDialog = (product) => {
    setSelectedProduct(product);
    setProductName(product.name);
    setProductDescription(product.description);
    setProductPrice(product.price);
    setProductImageUrl(product.image); // Establecer la URL de la imagen existente
    setProductCategory(product.idcategory); // Establecer la categoría del producto
    setProductImage(null);
    setDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setDialogOpen(false);
    setSelectedProduct(null);
    setProductName("");
    setProductDescription("");
    setProductPrice("");
    setProductImage(null);
    setProductCategory(""); // Establecer la categoría del producto
    setProductImageUrl(""); // Reiniciar la URL de la imagen al cerrar el diálogo
  };

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setProductName("");
    setProductDescription("");
    setProductPrice("");
    setProductImage(null);
    setProductCategory(""); // Establecer la categoría del producto

    setProductImageUrl(""); // Reiniciar la URL de la imagen al cerrar el diálogo
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProductImage(e.target.files[0]);
      setProductImageUrl(URL.createObjectURL(e.target.files[0])); // Actualizar la previsualización con la nueva imagen seleccionada
    }
  };

  const handleToggleActive = async (product) => {
    const newStatus = !product.active;
    try {
      const productRef = doc(db, "Productos", product.key);
      await updateDoc(productRef, {
        active: newStatus,
      });
      setProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.id === product.id ? { ...p, active: newStatus } : p
        )
      );
    } catch (error) {
      console.error("Error updating product status:", error);
      alert(
        "Hubo un error al actualizar el estado del producto. Por favor, intenta de nuevo."
      );
    }
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const updateProductPopularity = async (productId, popularity) => {
    try {
      const productRef = doc(db, "Productos", productId);
      await updateDoc(productRef, {
        popular: popularity,
      });
      alert("Popularidad del producto actualizada con éxito.");
    } catch (error) {
      console.error("Error actualizando la popularidad del producto:", error);
      alert(
        "Hubo un error al actualizar la popularidad del producto. Por favor, intenta de nuevo."
      );
    }
  };

  const handleDrawerToggle = () => {
    setOpenmenu(!openmenu);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Productos
          </Typography>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        variant="persistent"
        open={openmenu}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/productos/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippers/${comercioId}`}
            isActive={isActiveRoute(`/shippers/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Motorizados" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>

      <Main open={openmenu}>
        <ProductsContent>
          <Typography variant="h5" gutterBottom style={{ marginTop: "2rem" }}>
            Productos Existentes
          </Typography>
          <Grid container spacing={2}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={3} key={product.id}>
                <ProductCard>
                  <ProductImage
                    image={product.image || "https://via.placeholder.com/140"}
                    title={product.name}
                  />

                  <ProductContent>
                    <ProductName gutterBottom variant="h5" component="div">
                      {product.name}
                    </ProductName>
                    <ProductDescription variant="body2" color="text.secondary">
                      {product.description}
                    </ProductDescription>
                    <Typography variant="body2" color="text.primary">
                      Precio: ¢{product.price}
                    </Typography>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={product.active}
                          onChange={() => handleToggleActive(product)}
                          color="primary"
                          name="activeSwitch"
                          inputProps={{ "aria-label": "Producto Activo" }}
                        />
                      }
                      label="Disponible"
                    />
                  </ProductContent>
                  <CardActionsStyled>
                    <ActionButton
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleOpenEditDialog(product)}
                    >
                      <Edit />
                    </ActionButton>

                    <IconButton
                      color="primary"
                      onClick={(event) => handleClick(event, product)}
                    >
                      <Add />
                    </IconButton>
                    <ActionButton color="secondary">
                      <Delete />
                    </ActionButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem
                        onClick={() => {
                          updateProductPopularity(selectedProduct.key, 3);
                          handleClose();
                        }}
                      >
                        <Star /> Recomendado
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          updateProductPopularity(selectedProduct.key, 2);
                          handleClose();
                        }}
                      >
                        <StarBorder /> Destacado
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          updateProductPopularity(selectedProduct.key, 1);
                          handleClose();
                        }}
                      >
                        <Favorite /> Popular
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          updateProductPopularity(selectedProduct.key, 0);
                          handleClose();
                        }}
                      >
                        <StarBorder /> Mejor
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <Delete /> Eliminar
                      </MenuItem>
                    </Menu>
                  </CardActionsStyled>
                </ProductCard>
              </Grid>
            ))}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleLoadMore}
            disabled={loading}
            fullWidth
            style={{ marginTop: "1rem" }}
          >
            {loading ? "Cargando..." : "Cargar más"}
          </Button>

          {/* Dialog para crear producto */}
          <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
            <DialogTitle>Agregar Producto</DialogTitle>
            <DialogContent>
              <form onSubmit={handleAddProduct}>
                <TextField
                  label="Nombre del Producto"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Descripción del Producto"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />
                <TextField
                  label="Precio del Producto"
                  type="number"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Select
                  value={productCategory}
                  onChange={(e) => setProductCategory(e.target.value)}
                  fullWidth
                  margin="normal"
                  displayEmpty
                >
                  <MenuItem value="">Seleccionar Categoría</MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="contained"
                  component="label"
                  style={{ marginTop: "10px" }}
                >
                  Subir Imagen
                  <input type="file" hidden onChange={handleImageChange} />
                </Button>
                {productImageUrl && (
                  <Box mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      Previsualización:
                    </Typography>
                    <img
                      src={productImageUrl}
                      alt="Imagen del Producto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                )}
                <DialogActions>
                  <Button onClick={handleCloseCreateDialog} color="secondary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary">
                    Agregar Producto
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>

          {/* Dialog para editar producto */}
          <Dialog open={dialogOpen} onClose={handleCloseEditDialog}>
            <DialogTitle>Editar Producto</DialogTitle>
            <DialogContent>
              <TextField
                label="Nombre del Producto"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Descripción del Producto"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                label="Precio del Producto"
                type="number"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Select
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                fullWidth
                margin="normal"
              >
                <MenuItem value="">Seleccionar Categoría</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                component="label"
                style={{ marginTop: "10px" }}
              >
                Subir Imagen
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {productImageUrl && (
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    Imagen Actual:
                  </Typography>
                  <img
                    src={productImageUrl}
                    alt="Imagen del Producto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog} color="secondary">
                Cancelar
              </Button>
              <Button onClick={handleEditProduct} color="primary">
                Guardar Cambios
              </Button>
            </DialogActions>
          </Dialog>

          {/* Botón flotante para agregar producto */}
          <FabButton
            aria-label="Agregar Producto"
            onClick={handleOpenCreateDialog}
          >
            <AddIcon />
          </FabButton>
        </ProductsContent>
      </Main>
    </Box>
  );
};
export default Products;
