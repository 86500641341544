import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Divider,
  Box,
  TextField,
  CardMedia,
  AppBar,
  Toolbar,
  CssBaseline,
  MenuItem,
  ListItem,
  ListItemText,
  Drawer,
  List,
  ListItemIcon,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";

import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "../firebaseConfig";
import ComercioContext from "../ComercioContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { styled } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import {
  Add as AddIcon,
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category"; // Importa el icono aquí
const drawerWidth = 240;

const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const ProductImage = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: "56.25%", // 16:9
}));
const Main = styled(Box)(({ open }) => ({
  flexGrow: 1,
  padding: 24,
  backgroundColor: "#fafafa",
  minHeight: "100vh",
  marginLeft: open ? drawerWidth : 0,
  transition: "margin-left 0.3s",
  display: "flex",
  justifyContent: "flex-start", // Asegúrate de que el contenido se alinee correctamente
}));

const CartPanel = styled(Box)(({ theme }) => ({
  width: "400px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  p: 3,
  position: "fixed",
  right: 0,
  top: 100,
  bottom: 0,
  overflowY: "auto",
  zIndex: 1200, // Ajusta el z-index para asegurarte de que esté por encima del contenido principal
}));

const Content = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingTop: "100px",
  paddingLeft: "50px",

  paddingRight: "400px", // Deja espacio para el panel del carrito
}));

const POS = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [clientName, setClientName] = useState("");
  const [products, setProducts] = useState([]);
  const { comercioId } = useContext(ComercioContext);
  const location = useLocation();
  const [openmenu, setOpenmenu] = useState(true);
  const [expressShippingCost, setExpressShippingCost] = useState(0);
  const [deliveryAddress, setDeliveryAddress] = useState("");

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };
  useEffect(() => {
    const fetchProducts = () => {
      const productsQuery = query(
        collection(db, "Productos"),
        where("idcomercio", "==", comercioId)
      );

      const unsubscribe = onSnapshot(
        productsQuery,
        (snapshot) => {
          const productsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setProducts(productsList);
        },
        (error) => {
          console.error("Error al obtener productos:", error);
        }
      );

      return unsubscribe;
    };

    const unsubscribe = fetchProducts();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useEffect(() => {
    updateTotal();
  }, [selectedProducts, expressShippingCost]);

  const addProduct = (product) => {
    const productPrice = parseFloat(product.price);

    const existingProduct = selectedProducts.find((p) => p.id === product.id);
    if (existingProduct) {
      setSelectedProducts(
        selectedProducts.map((p) =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        )
      );
    } else {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
    setTotal(total + productPrice);
  };

  const removeProduct = (productId) => {
    const productToRemove = selectedProducts.find((p) => p.id === productId);
    if (productToRemove) {
      setTotal(total - productToRemove.price * productToRemove.quantity);
      setSelectedProducts(selectedProducts.filter((p) => p.id !== productId));
    }
  };

  const updateQuantity = (productId, quantity) => {
    const updatedProducts = selectedProducts.map((p) =>
      p.id === productId ? { ...p, quantity: parseInt(quantity, 10) || 1 } : p
    );

    const newTotal = updatedProducts.reduce(
      (sum, p) => sum + p.price * p.quantity,
      0
    );
    setTotal(newTotal);
    setSelectedProducts(updatedProducts);
  };

  const printComanda = () => {
    const comandaContent = `
      <html>
      <head>
        <title>Comanda</title>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; }
          h2 { text-align: center; margin-bottom: 20px; }
          .header { margin-bottom: 20px; }
          .header p { margin: 0; font-size: 14px; }
          .products { margin-bottom: 20px; }
          .product { display: flex; justify-content: space-between; margin-bottom: 10px; }
          .product-name { flex: 2; font-weight: bold; }
          .product-qty { flex: 1; text-align: center; }
          .product-price { flex: 1; text-align: right; }
          .total { text-align: right; font-size: 18px; font-weight: bold; margin-top: 20px; }
          .extra-info { margin-top: 20px; font-size: 14px; }
        </style>
      </head>
      <body>
        <h2>Comanda</h2>
        <div class="header">
          <p><strong>Cliente:</strong> ${clientName}</p>
          <p><strong>Fecha:</strong> ${new Date().toLocaleString()}</p>
          <p><strong>Dirección de Entrega:</strong> ${deliveryAddress}</p>
        </div>
        <div class="products">
          ${selectedProducts
            .map(
              (product) => `
            <div class="product">
              <div class="product-name">${product.name}</div>
              <div class="product-qty">x${product.quantity}</div>
              <div class="product-price">${
                product.price * product.quantity
              } Colones</div>
            </div>`
            )
            .join("")}
        </div>
        <div class="extra-info">
          <p><strong>Costo del Express:</strong> ${expressShippingCost} Colones</p>
        </div>
        <div class="total">Total: ${total + expressShippingCost} Colones</div>
        <script>
          window.onload = function() {
            window.print();
            setTimeout(() => { window.close(); }, 1000);
          };
        </script>
      </body>
      </html>
    `;

    const printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write(comandaContent);
    printWindow.document.close();

    resetCart();
  };

  const resetCart = () => {
    // Aquí debes restablecer el estado del carrito
    setSelectedProducts([]); // Limpia la lista de productos seleccionados
    setTotal(0); // Restablece el total a 0
    setExpressShippingCost(0); // Restablece el costo del express
    setDeliveryAddress(""); // Limpia la dirección de entrega
    setClientName("");
    // Puedes agregar más valores que necesites restablecer aquí
  };

  const finalizeOrder = () => {
    printComanda();
  };

  const handleDrawerToggle = () => {
    setOpenmenu(!openmenu);
  };

  const updateTotal = () => {
    const newTotal = selectedProducts.reduce(
      (sum, p) => sum + p.price * p.quantity,
      0
    );
    setTotal(newTotal + expressShippingCost);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            POS
          </Typography>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        variant="persistent"
        open={openmenu}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/POS/${comercioId}`}
            isActive={isActiveRoute(`/POS/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="POS" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/products/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippers/${comercioId}`}
            isActive={isActiveRoute(`/shippers/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Motorizados" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>
      <Content>
        {/* Panel de Productos */}
        <Grid container spacing={3} sx={{ flex: 2, overflowY: "auto", pr: 3 }}>
          {products.map((product) => (
            <Grid item xs={6} sm={4} md={3} key={product.id}>
              <Card
                sx={{
                  mb: 3,
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => addProduct(product)}
              >
                <ProductImage
                  image={product.image || "https://via.placeholder.com/140"}
                  title={product.name}
                />
                <CardContent>
                  <Typography variant="h6">{product.name}</Typography>
                  <Typography variant="body1">
                    {product.price} Colones
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Content>

      {/* Panel de Carrito */}
      {/* Panel de Carrito */}
      <CartPanel>
        <Typography variant="h5" gutterBottom>
          <ShoppingCartIcon /> Carrito
        </Typography>
        <Divider />

        {/* Campo para nombre del cliente */}
        <TextField
          label="Nombre del Cliente"
          variant="outlined"
          fullWidth
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
          sx={{ mb: 3 }}
        />
        <TextField
          label="Dirección de Entrega"
          variant="outlined"
          fullWidth
          onChange={(e) => setDeliveryAddress(e.target.value)}
          sx={{ mb: 3 }}
        />
        {selectedProducts.length === 0 ? (
          <Typography variant="body1" sx={{ mt: 3 }}>
            No hay productos en el carrito.
          </Typography>
        ) : (
          selectedProducts.map((product) => (
            <Box
              key={product.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="body1">
                {product.name} x
                <TextField
                  type="number"
                  value={product.quantity}
                  onChange={(e) => updateQuantity(product.id, e.target.value)}
                  sx={{ width: "60px", mx: 1 }}
                />
              </Typography>
              <Typography variant="body1">
                {product.price * product.quantity} Colones
              </Typography>
              <IconButton
                onClick={() => removeProduct(product.id)}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))
        )}
        <Divider />
        <TextField
          label="Costo Envío Express"
          variant="outlined"
          type="number"
          value={expressShippingCost}
          onChange={(e) =>
            setExpressShippingCost(parseFloat(e.target.value) || 0)
          }
          sx={{ mt: 2, mb: 2 }}
        />

        <Typography sx={{ mt: 3, fontWeight: "bold", fontSize: "18px" }}>
          Total: {total} Colones
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={finalizeOrder}
          disabled={selectedProducts.length === 0 || !clientName}
          sx={{ mt: 3 }}
        >
          Finalizar Pedido
        </Button>
      </CartPanel>
    </Box>
  );
};

export default POS;
